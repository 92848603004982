import * as React from "react";
import {
  List,
  Datagrid,
  TextField,
  EmailField,
  EditButton,
  Filter,
  SearchInput,
  TextInput,
  BooleanInput,
  FunctionField,
  ReferenceInput,
  SelectInput,
  downloadCSV,
  ReferenceField,
} from "react-admin";
import { Button, InputAdornment } from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import { guestStatus } from "../../models/guestStatus";
import Chip from "@material-ui/core/Chip";
import jsonExport from "jsonexport/dist";

const cmRoomTypeFilters = [
  <TextInput
    source="cm_client_room_type_name"
    label="Client Room Type Name"
    alwaysOn
    resettable
    InputProps={{
      endAdornment: (
        <InputAdornment>
          <SearchIcon color="disabled" />
        </InputAdornment>
      ),
    }}
  />,
  <ReferenceInput
    source="cm_client_id"
    reference="cm_clients"
    sort={{ field: "name", order: "ASC" }}
    label="Client"
    alwaysOn
  >
    <SelectInput optionText="name" />
  </ReferenceInput>,
];

const CMRoomTypeList = (props) => {
  const authInfo = JSON.parse(localStorage.getItem("auth"));

  return (
    <List
      filters={cmRoomTypeFilters}
      filterDefaultValues={{ tenant_id: authInfo.tenant.id }}
      bulkActionButtons={false}
      {...props}
    >
      <Datagrid>
        <ReferenceField
          label="Client"
          source="cm_client_id"
          reference="cm_clients"
        >
          <TextField source="name" />
        </ReferenceField>
        <ReferenceField
          label="Room type"
          source="room_type_id"
          reference="room_types"
        >
          <TextField source="name" />
        </ReferenceField>
        <TextField source="cm_client_room_type_name" label="Client Room Type Name" />
        <EditButton style={{ float: "right" }} basePath="/cm_room_type_maps" />
      </Datagrid>
    </List>
  );
};

export default CMRoomTypeList;
