import React, { useContext, useEffect } from "react";
import {
  FormWithRedirect,
  TextInput,
  SelectInput,
  required,
  useTranslate,
  ReferenceInput,
  AutocompleteInput,
  SaveButton,
  FormDataConsumer,
  DateInput,
  minValue,
  Title,
} from "react-admin";
import { Typography, Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Card, CardContent } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import { DataProviderContext } from "react-admin";
import { useNotify, useRedirect } from "react-admin";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import { InputAdornment } from "@material-ui/core";

import IconButton from "@material-ui/core/IconButton";
import { withStyles } from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/Close";
import AddIcon from "@material-ui/icons/Add";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";
import Chip from "@material-ui/core/Chip";
import { accommodationStatus } from "../../models/accommodationStatus";
import { calanderMonth } from "../../models/calanderMonth";
import { formatter } from "../../utils/formatter";
import moment from "moment";
import { DndProvider } from "react-dnd";
import HTML5Backend from "react-dnd-html5-backend";
import ScrollContainer from "react-indiana-drag-scroll";
import { useDrag } from "react-dnd";
import { useDrop } from "react-dnd";
import SearchIcon from "@material-ui/icons/Search";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import { bookingApi } from "../../api/bookingApi";
import icHkR from "../../images/ico_hk_required.png";
import icBleudine from "../../images/ico_bleudine.png";
import { computeRoomChargesForAccommodation } from "../../services/pricingService";

const useStyles = makeStyles((theme) => ({
  formGrid: {
    paddingTop: "0!important",
    paddingBottom: "0!important",
  },
  formInput: {
    marginTop: "0!important",
    marginBottom: "0!important",
  },
  textBold: {
    fontWeight: "bold",
  },
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  subCardWrap: {
    minHeight: 260,
  },
  statusIndicatorIco: {
    width: 25,
    height: 25,
    marginRight: 10,
  },
  statusIndicatorWrap: {
    display: "inline-flex",
  },
  statusIndicatorTxt: {
    lineHeight: "20px",
  },
  statusIndicatorIcoBooking: {
    width: 25,
    height: 25,
    display: "inline-block",
    verticalAlign: "middle",
    marginRight: 10,
  },
}));

var today = new Date();
var tomorrow = new Date();
tomorrow.setDate(today.getDate() + 1);

const DialogTitle = (props) => {
  const classes = useStyles();
  const { children, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
};

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

const FrontDeskCalander = ({
  calanderDate,
  roomTypes,
  calanderCellWidth,
  accommodations,
  maintenances,
  rooms,
  setSelectedAccommodation,
  setOpenAccommodation,
  refreshAccommodations,
  refreshRoomTypes,
}) => {
  return (
    <div className="r-calendar">
      <DndProvider backend={HTML5Backend}>
        <ScrollContainer className="scroll-container" ignoreElements="td">
          <table className="table table-striped r-calendar-main-table">
            <CalanderHead calanderDate={calanderDate} />
            <CalanderBody
              roomTypes={roomTypes}
              calanderCellWidth={calanderCellWidth}
              calanderDate={calanderDate}
              accommodations={accommodations}
              maintenances={maintenances}
              rooms={rooms}
              setSelectedAccommodation={setSelectedAccommodation}
              setOpenAccommodation={setOpenAccommodation}
              refreshAccommodations={refreshAccommodations}
              refreshRoomTypes={refreshRoomTypes}
            />
          </table>
        </ScrollContainer>
      </DndProvider>
      {/* {bookingPopup} */}
    </div>
  );
};

const CalanderHead = ({ calanderDate }) => {
  let datesHtml = calanderDate.map((date) => {
    return (
      <th
        key={date.getTime()}
        style={
          date.setHours(0, 0, 0, 0) == new Date().setHours(0, 0, 0, 0)
            ? { backgroundColor: "rgb(80, 98, 126)!important" }
            : {}
        }
      >
        <span
          className="r-calendar-head-date"
          style={
            date.setHours(0, 0, 0, 0) == new Date().setHours(0, 0, 0, 0)
              ? { color: "white" }
              : {}
          }
        >
          {date.getDate()}
        </span>
        <div
          className="r-calendar-head-month"
          style={
            date.setHours(0, 0, 0, 0) == new Date().setHours(0, 0, 0, 0)
              ? { color: "white" }
              : {}
          }
        >
          {calanderMonth.getShortMonth(date.getMonth())}
        </div>
      </th>
    );
  });

  return (
    <thead>
      <tr>
        <th style={{ verticalAlign: "middle" }}>
          <div className="text-right">ROOMS</div>
        </th>
        {datesHtml}
      </tr>
    </thead>
  );
};

const CalanderBody = ({
  roomTypes,
  calanderCellWidth,
  calanderDate,
  accommodations,
  maintenances,
  rooms,
  setSelectedAccommodation,
  setOpenAccommodation,
  refreshAccommodations,
  refreshRoomTypes,
}) => {
  let body = roomTypes
    .filter((item) => item.rooms.length > 0)
    .map((roomType) => {
      let roomTypeBody = (
        <RoomType
          key={roomType.id}
          roomType={roomType}
          accommodations={accommodations}
          maintenances={maintenances}
          dates={calanderDate}
          cellWith={calanderCellWidth}
          setSelectedAccommodation={setSelectedAccommodation}
          setOpenAccommodation={setOpenAccommodation}
          refreshAccommodations={refreshAccommodations}
        ></RoomType>
      );

      let roomBody = roomType.rooms.map((room) => {
        return (
          <Room
            key={room.id}
            room={room}
            accommodations={accommodations}
            maintenances={maintenances}
            rooms={rooms}
            dates={calanderDate}
            cellWith={calanderCellWidth}
            setSelectedAccommodation={setSelectedAccommodation}
            setOpenAccommodation={setOpenAccommodation}
            refreshAccommodations={refreshAccommodations}
            refreshRoomTypes={refreshRoomTypes}
          ></Room>
        );
      });

      return [roomTypeBody, ...roomBody];
    });

  return <tbody>{body}</tbody>;
};

const RoomType = ({
  key,
  roomType,
  accommodations,
  maintenances,
  dates,
  cellWidth,
  setSelectedAccommodation,
  setOpenAccommodation,
  refreshAccommodations,
}) => {
  let daysTd = dates.map((day, index) => {
    // get all booking for current day
    let bookinksToday = accommodations.filter((singleBook) => {
      let from_date = new Date(singleBook.check_in_date);
      return from_date.toDateString() === day.toDateString() &&
        singleBook.room_type_id === roomType.id &&
        singleBook.room_id == null &&
        singleBook.status == "New"
        ? true
        : false;
    });

    // get all booking jsx code for current day
    let bookinksTodayJsx = bookinksToday.map((singleBook) => {
      return (
        <Booking
          book={singleBook}
          key={singleBook.id}
          setSelectedAccommodation={setSelectedAccommodation}
          setOpenAccommodation={setOpenAccommodation}
        />
      );
    });
    return (
      <RoomTypeDate
        key={index}
        day={day}
        roomType={roomType}
        cellWidth={cellWidth}
        bookinksTodayJsx={bookinksTodayJsx}
        refreshAccommodations={refreshAccommodations}
        accommodations={accommodations}
        maintenances={maintenances}
      >
        {bookinksTodayJsx}
      </RoomTypeDate>
    );
  });

  return (
    <tr key={roomType.id}>
      <td>
        <div style={{ border: "none" }}>{roomType.name}</div>
      </td>
      {daysTd}
    </tr>
  );
};

const Room = ({
  key,
  room,
  accommodations,
  maintenances,
  rooms,
  dates,
  cellWidth,
  setSelectedAccommodation,
  setOpenAccommodation,
  refreshAccommodations,
  refreshRoomTypes,
  subscriptionSummary = (JSON.parse(localStorage.getItem("auth"))).merchantCore.subscriptionSummary
}) => {
  const classes = useStyles();
  const notify = useNotify();
  const dataProvider = useContext(DataProviderContext);

  let daysTd = dates.map((day, index) => {
    // get all booking for current day
    let bookinksToday = accommodations.filter((singleBook) => {
      let from_date = new Date(singleBook.check_in_date);
      return from_date.toDateString() === day.toDateString() &&
        singleBook.room_id === room.id &&
        singleBook.status != "Cancelled"
        ? true
        : false;
    });

    // get all booking jsx code for current day
    let bookinksTodayJsx = bookinksToday.map((singleBook) => {
      return (
        <Booking
          book={singleBook}
          key={singleBook.id}
          setSelectedAccommodation={setSelectedAccommodation}
          setOpenAccommodation={setOpenAccommodation}
        />
      );
    });

    let maintenancesToday = maintenances.filter((singleMaintenance) => {
      let from_date = new Date(singleMaintenance.start_date);
      return from_date.toDateString() === day.toDateString() &&
        singleMaintenance.room_id === room.id
        ? true
        : false;
    });

    // get all maintenance jsx code for current day
    let maintenancesTodayJsx = maintenancesToday.map((singleMaintenance) => {
      return (
        <Maintenance
          maintenance={singleMaintenance}
          key={singleMaintenance.id}
        />
      );
    });

    return (
      <RoomDate
        key={index}
        day={day}
        room={room}
        cellWidth={cellWidth}
        bookinksTodayJsx={bookinksTodayJsx}
        maintenancesTodayJsx={maintenancesTodayJsx}
        refreshAccommodations={refreshAccommodations}
        accommodations={accommodations}
        maintenances={maintenances}
        rooms={rooms}
      >
        {bookinksTodayJsx}
        {maintenancesTodayJsx}
      </RoomDate>
    );
  });

  let todayAccommodation = accommodations.filter((singleBook) => {
    let from_date = new Date(singleBook.check_in_date);
    let to_date = new Date(singleBook.check_out_date);
    return (
      from_date.setHours(0, 0, 0, 0) <= new Date().setHours(0, 0, 0, 0) &&
      to_date.setHours(0, 0, 0, 0) > new Date().setHours(0, 0, 0, 0) &&
      singleBook.room_id === room.id &&
      singleBook.status == "Checked_In"
    );
  });

  let todayRoomStatusColor = "green";
  if (todayAccommodation.length > 0) {
    todayRoomStatusColor = "orange";
  }

  let todayMaintenance = maintenances.filter((singleBook) => {
    let from_date = new Date(singleBook.start_date);
    let to_date = new Date(singleBook.end_date);
    return (
      from_date.setHours(0, 0, 0, 0) <= new Date().setHours(0, 0, 0, 0) &&
      to_date.setHours(0, 0, 0, 0) > new Date().setHours(0, 0, 0, 0) &&
      singleBook.room_id === room.id
    );
  });

  if (todayMaintenance.length > 0) {
    todayRoomStatusColor = "indianred";
  }

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleUpdateHouseKeeping = () => {
    notify("Loading ...");
    handleClose();
    let clean_status = "Dirty";
    if (room.clean_status == "Dirty") {
      clean_status = "Clean";
    }

    dataProvider
      .update("rooms", {
        id: room.id,
        data: { clean_status },
      })
      .then(({ data }) => {
        refreshRoomTypes();
        notify("Housekeeping updated");
      })
      .catch((error) => {
        notify(error);
      });
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <tr key={room.id}>
      <td>
        <div style={{ borderColor: todayRoomStatusColor }}>
          {
            subscriptionSummary && subscriptionSummary.housekeeping ?
              (
                room.clean_status == "Dirty" ? (
                  <img
                    src={icHkR}
                    style={{ width: 25, height: 25, float: "left", marginTop: 20 }}
                  />
                ) : (
                  <div style={{ width: 25, height: 25, float: "left" }}></div>
                )
              )
              : null}
          {room.name}
          <Button
            style={{ float: "right", height: 48 }}
            aria-controls="housekeeping-menu"
            aria-haspopup="true"
            onClick={handleClick}
          >
            <MoreVertIcon style={{ fontSize: 20 }} />
          </Button>
        </div>
        <Menu
          id="housekeeping-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          <MenuItem onClick={handleUpdateHouseKeeping}>
            {room.clean_status == "Dirty"
              ? "Housekeeping Completed"
              : "Request Housekeeping"}
          </MenuItem>
        </Menu>
      </td>
      {daysTd}
    </tr>
  );
};

const Booking = ({
  book,
  key,
  setSelectedAccommodation,
  setOpenAccommodation,
  subscriptionSummary = (JSON.parse(localStorage.getItem("auth"))).merchantCore.subscriptionSummary
}) => {
  const classes = useStyles();

  // enable dragging of component
  const [{ isDragging }, drag] = useDrag({
    item: { type: "booking", singleBooking: book },
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging(),
    }),
  });

  const openEditAccommodation = (e) => {
    e.stopPropagation();
    e.preventDefault();
    setSelectedAccommodation(book);
    setOpenAccommodation(true);
  };

  const getContent = () => {
    return book.booking.guest.display_name;
  };

  const getTitle = () => {
    return (
      formatter.bookingNo(book.booking.booking_no) +
      " - " +
      book.booking.guest.display_name
    );
  };

  let number_of_days =
    (new Date(book.check_out_date).getTime() -
      new Date(book.check_in_date).getTime()) /
    (60 * 60 * 24 * 1000);

  if (number_of_days > 0) {
    let style = {
      width:
        "calc(" +
        number_of_days * 100 +
        "% + " +
        number_of_days * 2 +
        "px - 35px )",
      borderColor:
        book.room_id != null
          ? accommodationStatus.getColor(book.e_accommodation_status.id)
          : "grey",
      background:
        book.room_id != null
          ? accommodationStatus.getLightLinearGradient(
            book.e_accommodation_status.id
          )
          : "linear-gradient(rgba(128, 128, 128, 0.75), rgba(128, 128, 128, 0.75))",
    };
    return (
      <div
        onClick={(e) => openEditAccommodation(e)}
        ref={drag}
        className="booking"
        style={style}
      >
        <div title={getTitle()} className="booking-inner">
          {
            subscriptionSummary && subscriptionSummary.bleudashBleudineIntegration ?
              (book.booking.guest.bleudine_user_id != null ? (
                <img
                  src={icBleudine}
                  style={{
                    width: 25,
                    height: 25,
                    verticalAlign: "middle",
                    marginRight: 10,
                  }}
                />
              ) : null)
              : null
          }

          {getContent()}
        </div>
      </div>
    );
  } else {
    return <></>;
  }
};

const Maintenance = ({ maintenance, key }) => {
  const classes = useStyles();

  // enable dragging of component
  const [{ isDragging }, drag] = useDrag({
    item: { type: "maintenance", singleMaintenance: maintenance },
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging(),
    }),
  });

  const getContent = () => {
    return "Maintenance - " + maintenance.title;
  };

  const getTitle = () => {
    return "Maintenance - " + maintenance.title;
  };

  let number_of_days =
    (new Date(maintenance.end_date).getTime() -
      new Date(maintenance.start_date).getTime()) /
    (60 * 60 * 24 * 1000);

  if (number_of_days > 0) {
    let style = {
      width:
        "calc(" +
        number_of_days * 100 +
        "% + " +
        number_of_days * 2 +
        "px - 35px )",
      borderColor: "indianred",
      background:
        "linear-gradient(rgba(205, 92, 92, 0.75), rgba(205, 92, 92, 0.75))",
      // background: "indianred",
    };
    return (
      <div className="maintenance" style={style}>
        <div title={getTitle()} className="maintenance-inner">
          {getContent()}
        </div>
      </div>
    );
  } else {
    return <></>;
  }
};

const RoomTypeDate = ({
  key,
  day,
  roomType,
  cellWidth,
  bookinksTodayJsx,
  refreshAccommodations,
  accommodations,
  maintenances,
}) => {
  const notify = useNotify();
  const dataProvider = useContext(DataProviderContext);

  const [{ isOver }, drop] = useDrop({
    accept: "booking",

    drop: (singleBookingDraggableItem) => {
      let isValid = true;
      if (
        singleBookingDraggableItem.singleBooking.status != "New" &&
        singleBookingDraggableItem.singleBooking.status != "Confirmed"
      ) {
        isValid = false;
        notify(
          "Only accommodation that yet to check in can change date & room."
        );
      } else if (
        singleBookingDraggableItem.singleBooking.room_type_id != roomType.id
      ) {
        isValid = false;
        notify("Only same room type allowed to modify.");
      }

      if (isValid) {
        notify("Loading ...");
        let number_of_days =
          (new Date(
            singleBookingDraggableItem.singleBooking.check_out_date
          ).getTime() -
            new Date(
              singleBookingDraggableItem.singleBooking.check_in_date
            ).getTime()) /
          (60 * 60 * 24 * 1000);
        let check_in_date = day;
        let check_out_date = new Date(day);
        check_out_date.setDate(check_out_date.getDate() + number_of_days);

        var request = {
          room_id: null,
          check_in_date: moment(check_in_date).format("YYYY-MM-DD"),
          check_out_date: moment(check_out_date).format("YYYY-MM-DD"),
          status: "New",
        };
        dataProvider
          .update("accommodations", {
            id: singleBookingDraggableItem.singleBooking.id,
            data: { ...request },
          })
          .then(({ data }) => {
            refreshAccommodations();
            notify("Accommodation updated");
          })
          .catch((error) => {
            notify(error);
          });
      }
    },

    collect: (monitor) => ({
      isOver: !!monitor.isOver(),
    }),
  });

  let occupiedAccommodations = accommodations.filter((item) => {
    let from_date = new Date(item.check_in_date);
    let to_date = new Date(item.check_out_date);
    return (
      from_date.setHours(0, 0, 0, 0) <= day.setHours(0, 0, 0, 0) &&
      to_date.setHours(0, 0, 0, 0) > day.setHours(0, 0, 0, 0) &&
      item.room_type_id == roomType.id &&
      item.status != "Cancelled"
    );
  });

  let occupiedMaintenances = maintenances.filter((item) => {
    let from_date = new Date(item.start_date);
    let to_date = new Date(item.end_date);
    return (
      from_date.setHours(0, 0, 0, 0) <= day.setHours(0, 0, 0, 0) &&
      to_date.setHours(0, 0, 0, 0) > day.setHours(0, 0, 0, 0) &&
      item.room.room_type_id == roomType.id
    );
  });

  let remainingRoom =
    roomType.rooms.length -
    occupiedAccommodations.length -
    occupiedMaintenances.length;
  let todayStatusColor = "indianred";
  if (remainingRoom > 0) {
    todayStatusColor = "darkseagreen";
  }

  return (
    <td
      ref={drop}
      key={day.getTime()}
      style={
        day.setHours(0, 0, 0, 0) == new Date().setHours(0, 0, 0, 0)
          ? {
            borderLeft: "1px solid rgb(80, 98, 126)",
            borderRight: "1px solid rgb(80, 98, 126)",
            width: cellWidth + "px",
          }
          : { width: cellWidth + "px" }
      }
    >
      <div
        className="day-indicator"
        style={{ background: todayStatusColor, color: "white" }}
      >
        {remainingRoom}
      </div>
      {bookinksTodayJsx}
    </td>
  );
};

const RoomDate = ({
  key,
  day,
  room,
  cellWidth,
  bookinksTodayJsx,
  maintenancesTodayJsx,
  refreshAccommodations,
  accommodations,
  maintenances,
  rooms,
}) => {
  const notify = useNotify();
  const dataProvider = useContext(DataProviderContext);

  const [{ isOver }, drop] = useDrop({
    accept: "booking",

    drop: (singleBookingDraggableItem) => {
      let isValid = true;
      if (
        singleBookingDraggableItem.singleBooking.status != "New" &&
        singleBookingDraggableItem.singleBooking.status != "Confirmed"
      ) {
        isValid = false;
        notify(
          "Only accommodation that yet to check in can change date & room."
        );
      } else if (
        singleBookingDraggableItem.singleBooking.room_type_id !=
        room.room_type_id
      ) {
        isValid = false;
        notify("Only same room type allowed to modify.");
      } else if (
        moment(day).format("YYYY-MM-DD") !=
        singleBookingDraggableItem.singleBooking.check_in_date
      ) {
        isValid = false;
        notify("Only same date allowed to modify.");
      }

      var isValidRoom = true;
      var isValidSlot = true;
      if (room.id != null) {
        // check room
        let selectedRoom = rooms.filter((item) => item.id == room.id)[0];
        if (selectedRoom.status != "Active") {
          isValidRoom = false;
        }

        //check availability
        let number_of_days =
          (new Date(
            singleBookingDraggableItem.singleBooking.check_out_date
          ).getTime() -
            new Date(
              singleBookingDraggableItem.singleBooking.check_in_date
            ).getTime()) /
          (60 * 60 * 24 * 1000);

        let check_in_date = day;
        let check_out_date = new Date(day);
        check_out_date.setDate(check_out_date.getDate() + number_of_days);

        let from_date = new Date(check_in_date).setHours(0, 0, 0, 0);
        let to_date = new Date(check_out_date).setHours(0, 0, 0, 0);

        for (
          var dateArr = [], dt = new Date(from_date);
          dt < to_date;
          dt.setDate(dt.getDate() + 1)
        ) {
          dateArr.push(new Date(dt));
        }

        dateArr.map((date) => {
          var accommodationBlock = accommodations
            .filter(
              (acco) => acco.id != singleBookingDraggableItem.singleBooking.id
            )
            .filter(
              (acco) =>
                acco.room_id == room.id &&
                new Date(acco.check_in_date).setHours(0, 0, 0, 0) <=
                date.setHours(0, 0, 0, 0) &&
                new Date(acco.check_out_date).setHours(0, 0, 0, 0) >
                date.setHours(0, 0, 0, 0)
            );
          var maintenanceBlock = maintenances.filter(
            (maint) =>
              maint.room_id == room.id &&
              new Date(maint.start_date).setHours(0, 0, 0, 0) <=
              date.setHours(0, 0, 0, 0) &&
              new Date(maint.end_date).setHours(0, 0, 0, 0) >
              date.setHours(0, 0, 0, 0)
          );

          if (accommodationBlock.length > 0 || maintenanceBlock.length > 0) {
            isValidSlot = false;
          }
        });
      }

      if (!isValidRoom) {
        notify("Selected room is out of order");
      } else if (!isValidSlot) {
        notify("Selected date is not available");
      } else if (isValid) {
        notify("Loading ...");
        let number_of_days =
          (new Date(
            singleBookingDraggableItem.singleBooking.check_out_date
          ).getTime() -
            new Date(
              singleBookingDraggableItem.singleBooking.check_in_date
            ).getTime()) /
          (60 * 60 * 24 * 1000);

        let check_in_date = day;
        let check_out_date = new Date(day);
        check_out_date.setDate(check_out_date.getDate() + number_of_days);

        var request = {
          room_id: room.id,
          status: "Confirmed",
          check_in_date: moment(check_in_date).format("YYYY-MM-DD"),
          check_out_date: moment(check_out_date).format("YYYY-MM-DD"),
        };
        dataProvider
          .update("accommodations", {
            id: singleBookingDraggableItem.singleBooking.id,
            data: { ...request },
          })
          .then(({ data }) => {
            refreshAccommodations();
            notify("Accommodation updated");
          })
          .catch((error) => {
            notify(error);
          });
      }
    },

    collect: (monitor) => ({
      isOver: !!monitor.isOver(),
    }),
  });

  return (
    <td
      ref={drop}
      key={day.getTime()}
      style={
        day.setHours(0, 0, 0, 0) == new Date().setHours(0, 0, 0, 0)
          ? {
            borderLeft: "1px solid rgb(80, 98, 126)",
            borderRight: "1px solid rgb(80, 98, 126)",
            width: cellWidth + "px",
          }
          : { width: cellWidth + "px" }
      }
    >
      {bookinksTodayJsx}
      {maintenancesTodayJsx}
    </td>
  );
};

const AccommodationModal = ({
  openAccommodation,
  setOpenAccommodation,
  selectedAccommodation,
  availableRoomType,
  refreshAccommodations,
  refreshRoomTypes,
  accommodations,
  maintenances,
  rooms,
  selectedVAT
}) => {
  const authInfo = JSON.parse(localStorage.getItem("auth"));
  const classes = useStyles();
  const notify = useNotify();
  const dataProvider = useContext(DataProviderContext);
  const [saving, setSaving] = React.useState(false);

  function parseDate(str) {
    var mdy = str.split("-");
    return new Date(mdy[0], mdy[1] - 1, mdy[2]);
  }

  function datediff(first, second) {
    return Math.round((second - first) / (1000 * 60 * 60 * 24));
  }

  const updateAccommodation = (process, formData) => {
    setSaving(true);

    let booking = selectedAccommodation.booking;

    var isValidRoom = true;
    var isValidSlot = true;

    if (formData.room_id != null) {
      //check room
      let selectedRoom = rooms.filter((item) => item.id == formData.room_id)[0];
      if (selectedRoom.status != "Active") {
        isValidRoom = false;
      }

      //check availability
      let from_date = new Date(formData.check_in_date).setHours(0, 0, 0, 0);
      let to_date = new Date(formData.check_out_date).setHours(0, 0, 0, 0);
      for (
        var dateArr = [], dt = new Date(from_date);
        dt < to_date;
        dt.setDate(dt.getDate() + 1)
      ) {
        dateArr.push(new Date(dt));
      }
      dateArr.map((date) => {
        var accommodationBlock = accommodations
          .filter((acco) => acco.id != selectedAccommodation.id)
          .filter(
            (acco) =>
              acco.room_id == formData.room_id &&
              new Date(acco.check_in_date).setHours(0, 0, 0, 0) <=
              date.setHours(0, 0, 0, 0) &&
              new Date(acco.check_out_date).setHours(0, 0, 0, 0) >
              date.setHours(0, 0, 0, 0)
          );
        var maintenanceBlock = maintenances.filter(
          (maint) =>
            maint.room_id == formData.room_id &&
            new Date(maint.start_date).setHours(0, 0, 0, 0) <=
            date.setHours(0, 0, 0, 0) &&
            new Date(maint.end_date).setHours(0, 0, 0, 0) >
            date.setHours(0, 0, 0, 0)
        );

        if (accommodationBlock.length > 0 || maintenanceBlock.length > 0) {
          isValidSlot = false;
        }
      });
    }

    //check valid
    var isValid = true;
    var request = {};
    if (process == "update") {
      let existingOrder = booking.orders.filter((item) => {
        return (
          item.accommodation_id == selectedAccommodation.id &&
          item.order_type == "Accommodation" &&
          (item.status == "Invoiced" || item.status == "Paid")
        );
      });
      //if order is paid or invoiced, not allow update
      if (existingOrder.length > 0) {
        notify("Fail to update due to order already invoiced or paid");
        isValid = false;
      }

      request.check_in_date = formData.check_in_date;
      request.check_out_date = formData.check_out_date;
      request.room_type_id = formData.room_type_id;
      request.room_type_rate_id = formData.room_type_rate_id;
      request.room_id = formData.room_id;
      request.status =
        selectedAccommodation.status == "New" && formData.room_id != null
          ? "Confirmed"
          : selectedAccommodation.status;
    } else if (process == "checkin") {
      //if no room, not allow checkin
      if (!formData.room_id) {
        notify("Please select a room to check in");
        isValid = false;
      }
      request.check_in_date = formData.check_in_date;
      request.check_out_date = formData.check_out_date;
      request.room_type_id = formData.room_type_id;
      request.room_type_rate_id = formData.room_type_rate_id;
      request.room_id = formData.room_id;
      request.status = "Checked_In";
    } else if (process == "checkout") {
      debugger

      if (booking.e_payment_status.comment === "Unpaid") {
        notify("Please Paid all orders before Checked Out");
        isValid = false;
        setSaving(false);
        return
      }
      if (booking.e_payment_status.comment === "Overpaid") {
        notify("Please get your refund before Checked Out");
        isValid = false;
        setSaving(false);
        return
      }

      request.check_out_date = formData.check_out_date;
      request.status = "Checked_Out";
    } else if (process == "cancel") {
      let existingOrder = booking.orders.filter((item) => {
        return (
          item.accommodation_id == selectedAccommodation.id &&
          item.order_type == "Accommodation" &&
          (item.status == "Invoiced" || item.status == "Paid")
        );
      });
      //if order is invoiced or paid, not allow to cancel
      if (existingOrder.length > 0) {
        notify("Fail to update due to order already invoiced or paid");
        isValid = false;
      }
      request.status = "Cancelled";
    }

    if (!isValidRoom) {
      notify("Selected room is out of order");
      setSaving(false);
    } else if (!isValidSlot) {
      notify("Selected date is not available");
      setSaving(false);
    } else if (isValid) {
      dataProvider
        .update("accommodations", {
          id: selectedAccommodation.id,
          data: { ...request },
        })
        .then(({ data }) => {
          //update room dirty
          if (data.status == "Checked_Out") {
            dataProvider
              .update("rooms", {
                id: data.room_id,
                data: { clean_status: "Dirty" },
              })
              .then(({ data }) => {
                refreshRoomTypes();
              });
          }

          //update order if got changes
          let order = booking.orders.filter((item) => {
            return (
              item.accommodation_id == selectedAccommodation.id &&
              item.order_type == "Accommodation" &&
              item.status == "New"
            );
          });
          if (
            order.length > 0 &&
            (selectedAccommodation.check_in_date != formData.check_in_date ||
              selectedAccommodation.check_out_date != formData.check_out_date ||
              selectedAccommodation.room_type_id != formData.room_type_id ||
              selectedAccommodation.room_type_rate_id !=
              formData.room_type_rate_id)
          ) {
            let room_type = availableRoomType.filter((item) => {
              return item.id == formData.room_type_id;
            })[0];
            let room_type_rate = room_type.room_type_rates.filter((item) => {
              return item.id == formData.room_type_rate_id;
            })[0];

            let qty = datediff(
              parseDate(formData.check_in_date),
              parseDate(formData.check_out_date)
            )
            let price = room_type_rate.price

            let order_request = {
              qty: qty,
              price: room_type_rate.price,
              service_charge: selectedVAT,
              total_price:
                price * qty,
              description:
                room_type.name +
                " (" +
                formData.check_in_date +
                " - " +
                formData.check_out_date +
                ")",
            };
            dataProvider.update("orders", {
              id: order.id,
              data: { ...order_request },
            });
          }

          //update booking status
          let bookingStatus = "New";
          if (
            data.status == "Confirmed" ||
            booking.accommodations.filter(
              (record) =>
                record.id != selectedAccommodation.id &&
                record.status == "Confirmed"
            ).length > 0
          ) {
            //from new to confirm
            bookingStatus = "Confirmed";
          }
          if (
            data.status == "Checked_In" ||
            booking.accommodations.filter(
              (record) =>
                record.id != selectedAccommodation.id &&
                record.status == "Checked_In"
            ).length > 0
          ) {
            //from confirm to checkin
            bookingStatus = "Checked_In";
          }
          if (
            data.status == "Cancalled" &&
            booking.accommodations.filter(
              (record) =>
                record.id != selectedAccommodation.id &&
                record.status == "Cancelled"
            ).length ==
            data.accommodations.length - 1
          ) {
            //if all accommodation is cancel, to cancel
            bookingStatus = "Cancelled";
          }
          if (
            (data.status == "Cancalled" || data.status == "Checked_Out") &&
            booking.accommodations.filter(
              (record) =>
                record.id != selectedAccommodation.id &&
                (record.status == "New" ||
                  record.status == "Checked_In" ||
                  record.status == "Confirmed")
            ).length == 0
          ) {
            //if all is end, to complete
            bookingStatus = "Completed";
          }
          if (booking.status != bookingStatus) {
            dataProvider.update("bookings", {
              id: selectedAccommodation.booking_id,
              data: { status: bookingStatus },
            });
          }

          setOpenAccommodation(false);
          refreshAccommodations();
          notify("Accommodation updated");
          setSaving(false);
        })
        .catch((error) => {
          notify(error);
          setSaving(false);
        });
    } else {
      setSaving(false);
    }
  };

  return (
    <Dialog
      maxWidth="sm"
      fullWidth={true}
      onClose={() => setOpenAccommodation(false)}
      aria-labelledby="customized-dialog-title"
      open={openAccommodation}
    >
      <DialogTitle
        id="customized-dialog-title"
        onClose={() => setOpenAccommodation(false)}
      >
        {selectedAccommodation != null
          ? "Accommodation " +
          (selectedAccommodation.room != null
            ? selectedAccommodation.room.name
            : "-")
          : "Accommodation"}
      </DialogTitle>
      <FormWithRedirect
        onSubmit={() => { }}
        record={selectedAccommodation}
        render={(formProps) => (
          <div>
            <DialogContent dividers>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6} className={classes.formGrid}>
                  <DateInput
                    className={classes.formInput}
                    fullWidth
                    source="check_in_date"
                    label="Check In Date"
                    defaultValue={moment(today).format("YYYY-MM-DD")}
                    validate={[required()]}
                    disabled={true}
                  />
                </Grid>
                <Grid item xs={12} sm={6} className={classes.formGrid}>
                  <FormDataConsumer>
                    {({ formData, ...rest }) => (
                      <DateInput
                        className={classes.formInput}
                        fullWidth
                        source="check_out_date"
                        label="Check Out Date"
                        defaultValue={moment(tomorrow).format("YYYY-MM-DD")}
                        disabled={true}
                        validate={[
                          required(),
                          minValue(
                            moment(
                              new Date(formData.check_in_date).setDate(
                                new Date(formData.check_in_date).getDate() + 1
                              )
                            ).format("YYYY-MM-DD")
                          ),
                        ]}
                      />
                    )}
                  </FormDataConsumer>
                </Grid>

                <Grid item xs={12} sm={6} className={classes.formGrid}>
                  <SelectInput
                    className={classes.formInput}
                    fullWidth
                    label="Room Type"
                    source="room_type_id"
                    choices={availableRoomType.map(function (item) {
                      return { id: item.id, name: item.name };
                    })}
                    options={{
                      InputProps: { autoComplete: "no" },
                    }}
                    validate={[required()]}
                    disabled={true}
                  />
                </Grid>
                <Grid item xs={12} sm={6} className={classes.formGrid}>
                  <FormDataConsumer>
                    {({ formData, ...rest }) =>
                      formData.room_type_id ? (
                        <SelectInput
                          className={classes.formInput}
                          fullWidth
                          label="Room Type Rate"
                          source="room_type_rate_id"
                          choices={availableRoomType
                            .filter(
                              (fitem) => fitem.id === formData.room_type_id
                            )[0]
                            .room_type_rates.map((item) => {
                              return {
                                id: item.id,
                                name: item.room_rate.name,
                              };
                            })}
                          optionText="name"
                          options={{
                            InputProps: { autoComplete: "no" },
                          }}
                          validate={[required()]}
                          disabled={true}
                        />
                      ) : null
                    }
                  </FormDataConsumer>
                </Grid>
                <Grid item xs={6} className={classes.formGrid}>
                  <FormDataConsumer>
                    {({ formData, ...rest }) =>
                      formData.room_type_id && formData.room_type_rate_id ? (
                        <SelectInput
                          className={classes.formInput}
                          fullWidth
                          label="Room"
                          source="room_id"
                          choices={availableRoomType
                            .filter(
                              (fitem) => fitem.id === formData.room_type_id
                            )[0]
                            .rooms.map((item) => {
                              return { id: item.id, name: item.name };
                            })}
                          optionText="name"
                          options={{
                            InputProps: { autoComplete: "no" },
                          }}
                          disabled={true}
                        />
                      ) : null
                    }
                  </FormDataConsumer>
                </Grid>
                <Grid item xs={6} className={classes.formGrid}>
                  <FormDataConsumer>
                    {({ formData, ...rest }) =>
                      formData.room_type_id && formData.room_type_rate_id ? (
                        <SelectInput
                          className={classes.formInput}
                          fullWidth
                          label="Additional Adult"
                          source="additional_adult"
                          choices={availableRoomType
                            .filter(
                              (fitem) => fitem.id === formData.room_type_id
                            )[0]
                            .rooms.map((item) => {
                              return { id: item.id, name: item.name };
                            })}
                          optionText="name"
                          options={{
                            InputProps: { autoComplete: "no" },
                          }}
                          disabled={true}
                        />
                      ) : null
                    }
                  </FormDataConsumer>
                </Grid>
                <Grid item xs={6} className={classes.formGrid}>
                  <Typography gutterBottom>Cancellation Policy</Typography>
                </Grid>
                <FormDataConsumer>
                  {({ formData, ...rest }) => {
                    var selected_room_type = availableRoomType.filter(
                      (fitem) => fitem.id === formData.room_type_id
                    )[0];

                    var selected_room_type_rate = selected_room_type
                      ? selected_room_type.room_type_rates.filter(
                        (fitem) => fitem.id === formData.room_type_rate_id
                      )[0]
                      : null;

                    return (
                      <Grid
                        item
                        xs={6}
                        className={classes.formGrid}
                        align="right"
                      >
                        {selected_room_type_rate
                          ? selected_room_type_rate.room_rate
                            .cancellation_policy.name
                          : "-"}
                      </Grid>
                    );
                  }}
                </FormDataConsumer>
                <Grid item xs={6} className={classes.formGrid}>
                  <Typography gutterBottom>Total Price</Typography>
                </Grid>
                <FormDataConsumer>
                  {({ formData, ...rest }) => {
                    var selectedOrder = selectedAccommodation.booking.orders.filter((order) => order.accommodation_id === selectedAccommodation.id && order.order_type === "Accommodation")[0]
                    return (
                      <Grid
                        item
                        xs={6}
                        className={classes.formGrid}
                        align="right"
                      >
                        {selectedOrder
                          ? formatter.formatMoney(
                            selectedOrder.total_price,
                            authInfo.tenant.locale.currency_code,
                            authInfo.tenant.locale.precision
                          )
                          : "-"}
                      </Grid>
                    );
                  }}
                </FormDataConsumer>
                <Grid item xs={6} className={classes.formGrid}>
                  <Typography gutterBottom>Status</Typography>
                </Grid>
                <Grid
                  item
                  xs={6}
                  className={classes.formGrid}
                  style={{ textAlign: "right" }}
                >
                  <Chip
                    label={
                      selectedAccommodation?.e_accommodation_status.comment
                    }
                    style={{
                      color: "white",
                      background: accommodationStatus.getColor(
                        selectedAccommodation?.status
                      ),
                    }}
                  />
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button
                variant="contained"
                color="primary"
                href={`/bookings/${selectedAccommodation?.booking_id}`}
                target="_blank"
              >
                View Booking
              </Button>
              <FormDataConsumer>
                {({ formData, ...rest }) =>
                  selectedAccommodation?.status == "Confirmed" ? (
                    <SaveButton
                      {...formProps}
                      variant="contained"
                      color="primary"
                      label="Check In"
                      icon={<ArrowDownwardIcon />}
                      className={classes.formInput}
                      onSave={() => updateAccommodation("checkin", formData)}
                      saving={saving}
                      disabled={saving}
                    />
                  ) : null
                }
              </FormDataConsumer>
              {/* <FormDataConsumer>
                {({ formData, ...rest }) =>
                  selectedAccommodation?.status == "Confirmed" ? (
                    <SaveButton
                      {...formProps}
                      style={{ background: "red" }}
                      variant="contained"
                      color="primary"
                      label="Cancel"
                      icon={<ClearIcon />}
                      className={classes.formInput}
                      onSave={() => updateAccommodation("cancel", formData)}
                    />
                  ) : null
                }
              </FormDataConsumer> */}
              <FormDataConsumer>
                {({ formData, ...rest }) =>
                  selectedAccommodation?.status == "Checked_In" ? (
                    <SaveButton
                      {...formProps}
                      variant="contained"
                      color="primary"
                      label="Check Out"
                      icon={<ArrowUpwardIcon />}
                      className={classes.formInput}
                      onSave={() => updateAccommodation("checkout", formData)}
                      saving={saving}
                      disabled={saving}
                    />
                  ) : null
                }
              </FormDataConsumer>
            </DialogActions>
          </div>
        )}
      />
    </Dialog>
  );
};

const CalanderFilter = ({ setAccommodations, setAvailableRoomType }) => {
  const authInfo = JSON.parse(localStorage.getItem("auth"));
  const classes = useStyles();
  const notify = useNotify();
  const dataProvider = useContext(DataProviderContext);

  const filterCalander = (formData) => {
    var accommodationRequest = { tenant_id: authInfo.tenant.id };
    dataProvider
      .getList("accommodations", { filter: { ...accommodationRequest } })
      .then(({ data }) => {
        let response = data;
        if (formData.booking_no) {
          response = response.filter((item) =>
            formatter
              .bookingNo(item.booking.booking_no)
              .includes(formData.booking_no)
          );
        }
        if (formData.guest_id) {
          response = response.filter(
            (item) => item.booking.guest.id === formData.guest_id
          );
        }
        if(formData.cm_itenerary_no)
        {
          response = response.filter(
            (item) => item.booking.cm_itenerary_no === formData.cm_itenerary_no
          );
        }
        setAccommodations(response);
      })
      .catch((error) => {
        notify(error);
      });

    var roomTypeRequest = { tenant_id: authInfo.tenant.id };
    dataProvider
      .getList("room_types", { filter: { ...roomTypeRequest } })
      .then(({ data }) => {
        let response = data;
        if (formData.room_type_id) {
          response = response.filter(
            (item) => item.id == formData.room_type_id
          );
        }
        setAvailableRoomType(response);
      })
      .catch((error) => {
        notify(error);
      });
  };

  return (
    <FormWithRedirect
      onSubmit={() => { }}
      render={(formProps) => (
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="h6" className={classes.textBold} gutterBottom>
              Cockpit
              <Button
                style={{ float: "right", color: "#4f3cc9" }}
                variant="outline"
                color="primary"
                startIcon={<AddIcon />}
                href="/bookings/create"
                target="_blank"
              >
                New Booking
              </Button>
              <FormDataConsumer>
                {({ formData, ...rest }) => (
                  <Button
                    style={{ float: "right", color: "#4f3cc9" }}
                    variant="outline"
                    color="primary"
                    startIcon={<SearchIcon />}
                    onClick={() => filterCalander(formData)}
                  >
                    Search
                  </Button>
                )}
              </FormDataConsumer>
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={3} className={classes.formGrid}>
                <TextInput
                  resettable
                  className={classes.formInput}
                  source="booking_no"
                  label="Booking No"
                  fullWidth
                  InputProps={{
                    startAdornment: <InputAdornment>B</InputAdornment>,
                    endAdornment: (
                      <InputAdornment>
                        <SearchIcon color="disabled" />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={3} className={classes.formGrid}>
                <TextInput
                  resettable
                  className={classes.formInput}
                  source="cm_itenerary_no"
                  label="OTA Reference No"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={3} className={classes.formGrid}>
                <ReferenceInput
                  className={classes.formInput}
                  source="guest_id"
                  reference="guests"
                  filter={{ tenant_id: authInfo.tenant.id }}
                  sort={{ field: "display_name", order: "ASC" }}
                  filterToQuery={(searchText) => ({
                    display_name: searchText,
                  })}
                  label="Guest"
                  fullWidth
                  resettable
                >
                  <AutocompleteInput
                    resettable
                    optionText={(choice) => choice.email ? `${choice.display_name} - ${choice.email}` : choice.display_name}
                    options={{ InputProps: { autoComplete: "no" } }}
                  />
                </ReferenceInput>
              </Grid>
              <Grid item xs={12} sm={3} className={classes.formGrid}>
                <ReferenceInput
                  className={classes.formInput}
                  source="room_type_id"
                  reference="room_types"
                  filter={{ tenant_id: authInfo.tenant.id }}
                  sort={{ field: "name", order: "ASC" }}
                  filterToQuery={(searchText) => ({
                    name: searchText,
                  })}
                  label="Room Type"
                  fullWidth
                  resettable
                >
                  <AutocompleteInput
                    resettable
                    optionText="name"
                    options={{ InputProps: { autoComplete: "no" } }}
                  />
                </ReferenceInput>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      )}
    />
  );
};

const StatusIndicator = () => {
  const classes = useStyles();
  const subscriptionSummary = (JSON.parse(localStorage.getItem("auth"))).merchantCore.subscriptionSummary
  return (
    <Grid container spacing={2} style={{ marginBottom: 20 }}>
      <Grid item xs={12}>
        <Typography variant="h6" className={classes.textBold} gutterBottom>
          Status Indicator
        </Typography>
      </Grid>
      <Grid item xs={12} className={classes.formGrid}>
        <Card>
          <CardContent>
            <Grid container spacing={2} style={{ marginBottom: 20 }}>
              <Grid item xs={12} sm={2} className={classes.formGrid}>
                <div
                  className={classes.textBold}
                  style={{ lineHeight: "20px" }}
                >
                  Accommodation Status
                </div>
              </Grid>
              <Grid item xs={12} sm={10} className={classes.formGrid}>
                <Grid container>
                  <Grid item xs={12} sm={3}>
                    <div>
                      <div
                        className="status-indicator"
                        style={{ background: "grey" }}
                      ></div>{" "}
                      New
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={3}>
                    <div>
                      <div
                        className="status-indicator"
                        style={{ background: "darkblue" }}
                      ></div>{" "}
                      Confirmed
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={3}>
                    <div>
                      <div
                        className="status-indicator"
                        style={{ background: "purple" }}
                      ></div>{" "}
                      Checked In
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={3}>
                    <div>
                      <div
                        className="status-indicator"
                        style={{ background: "darkgreen" }}
                      ></div>{" "}
                      Checked Out
                    </div>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid container spacing={2} style={{ marginBottom: 20 }}>
              <Grid item xs={12} sm={2} className={classes.formGrid}>
                <div
                  className={classes.textBold}
                  style={{ lineHeight: "20px" }}
                >
                  Room Status
                </div>
              </Grid>
              <Grid item xs={12} sm={10} className={classes.formGrid}>
                <Grid container>
                  <Grid item xs={12} sm={3}>
                    <div>
                      <div
                        className="status-indicator"
                        style={{ background: "green" }}
                      ></div>{" "}
                      Vacant
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={3}>
                    <div>
                      <div
                        className="status-indicator"
                        style={{ background: "orange" }}
                      ></div>{" "}
                      Occupied
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={3}>
                    <div>
                      <div
                        className="status-indicator"
                        style={{ background: "indianred" }}
                      ></div>{" "}
                      Not Available
                    </div>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              {subscriptionSummary && subscriptionSummary.housekeeping || subscriptionSummary.maintenance ?
                (
                  <Grid item xs={12} sm={2} className={classes.formGrid}>
                    <div
                      className={classes.textBold}
                      style={{ lineHeight: "20px" }}
                    >
                      Indicator
                    </div>
                  </Grid>)
                : null
              }
              <Grid item xs={12} sm={10} className={classes.formGrid}>
                <Grid container>
                  {
                    subscriptionSummary && subscriptionSummary.housekeeping ?
                      (
                        <Grid item xs={12} sm={3}>
                          <div className={classes.statusIndicatorWrap}>
                            <img src={icHkR} className={classes.statusIndicatorIco} />
                            <div className={classes.statusIndicatorTxt}>
                              Housekeeping required
                            </div>
                          </div>
                        </Grid>
                      )
                      : null
                  }
                  {subscriptionSummary && subscriptionSummary.bleudashBleudineIntegration ?
                    (<Grid item xs={12} sm={3}>
                      <div className={classes.statusIndicatorWrap}>
                        <img
                          src={icBleudine}
                          className={classes.statusIndicatorIco}
                        />
                        <div className={classes.statusIndicatorTxt}>
                          Bleudine Membership
                        </div>
                      </div>
                    </Grid>)
                    : null}

                </Grid>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};

const FrontDesk = (props) => {
  const authInfo = JSON.parse(localStorage.getItem("auth"));
  const classes = useStyles();
  const translate = useTranslate();
  const notify = useNotify();
  const dataProvider = useContext(DataProviderContext);

  const { source, ...rest } = props;
  const [openAccommodation, setOpenAccommodation] = React.useState(false);
  const [selectedAccommodation, setSelectedAccommodation] =
    React.useState(null);
  const [availableRoomType, setAvailableRoomType] = React.useState([]);
  const [calanderDate, setCalanderDate] = React.useState([]);
  const [calanderCellWidth, setCalanderCellWidth] = React.useState(50);
  const [accommodations, setAccommodations] = React.useState([]);
  const [maintenances, setMaintenances] = React.useState([]);
  const [rooms, setRooms] = React.useState([]);
  let startDate = moment(new Date().setDate(new Date().getDate() - 60)).format(
    "YYYY-MM-DD"
  );
  let endDate = moment(new Date().setDate(new Date().getDate() + 90)).format(
    "YYYY-MM-DD"
  );
  const [selectedVAT, setSelectedVAT] = React.useState(0);

  useEffect(() => {
    let day = new Date();
    day.setDate(day.getDate() - 15);
    let dayArray = [new Date(day.setDate(day.getDate()))];
    for (let aa = 0; aa < 100; aa++) {
      let newDay = new Date(day.setDate(day.getDate() + 1));
      dayArray.push(newDay);
    }
    setCalanderDate(dayArray);

    var tenantRequest = { id: authInfo.tenant.id };
    dataProvider
      .getList("tenants", { filter: { ...tenantRequest } })
      .then(({ data }) => {
        setSelectedVAT(data[0].service_charge / 100)
      })
      .catch((error) => {
        notify(error);
      });

    bookingApi.getAccommodations(authInfo.tenant.id, startDate, endDate).then(
      (data) => {
        setAccommodations(data?.data ? data?.data?.accommodations : []);
      },
      (error) => {
        notify(error);
      }
    );

    bookingApi.getMaintenances(authInfo.tenant.id, startDate, endDate).then(
      (data) => {
        setMaintenances(data?.data ? data?.data?.maintenances : []);
      },
      (error) => {
        notify(error);
      }
    );

    var roomTypeRequest = { tenant_id: authInfo.tenant.id };
    dataProvider
      .getList("room_types", { filter: { ...roomTypeRequest } })
      .then(({ data }) => {
        setAvailableRoomType(data);
      })
      .catch((error) => {
        notify(error);
      });

    var roomRequest = { tenant_id: authInfo.tenant.id };
    dataProvider
      .getList("rooms", { filter: { ...roomRequest } })
      .then(({ data }) => {
        setRooms(data);
      })
      .catch((error) => {
        notify(error);
      });
  }, []);

  const refreshRoomTypes = () => {
    var roomTypeRequest = { tenant_id: authInfo.tenant.id };
    dataProvider
      .getList("room_types", { filter: { ...roomTypeRequest } })
      .then(({ data }) => {
        setAvailableRoomType(data);
      })
      .catch((error) => {
        notify(error);
      });
  };

  const refreshAccommodations = () => {
    bookingApi.getAccommodations(authInfo.tenant.id, startDate, endDate).then(
      (data) => {
        setAccommodations(data?.data ? data?.data?.accommodations : []);
      },
      (error) => {
        notify(error);
      }
    );
  };

  return (
    <div>
      <Title title={translate("ra.label.cockpit")} />
      <Card>
        <CardContent>
          <Grid item xs={12}>
            <CalanderFilter
              setAccommodations={setAccommodations}
              setAvailableRoomType={setAvailableRoomType}
            />
          </Grid>
          <Grid item xs={12}>
            <StatusIndicator />
          </Grid>
          <Grid item xs={12}>
            <FrontDeskCalander
              calanderDate={calanderDate}
              roomTypes={availableRoomType}
              calanderCellWidth={calanderCellWidth}
              accommodations={accommodations}
              maintenances={maintenances}
              rooms={rooms}
              setSelectedAccommodation={setSelectedAccommodation}
              setOpenAccommodation={setOpenAccommodation}
              refreshAccommodations={refreshAccommodations}
              refreshRoomTypes={refreshRoomTypes}
            />
          </Grid>
        </CardContent>
      </Card>
      <AccommodationModal
        openAccommodation={openAccommodation}
        setOpenAccommodation={setOpenAccommodation}
        selectedAccommodation={selectedAccommodation}
        availableRoomType={availableRoomType}
        refreshAccommodations={refreshAccommodations}
        refreshRoomTypes={refreshRoomTypes}
        accommodations={accommodations}
        maintenances={maintenances}
        rooms={rooms}
        selectedVAT={selectedVAT}
      />
    </div>
  );
};

export default FrontDesk;
