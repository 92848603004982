import gql from 'graphql-tag';

const GET_LIST_INVOICES = gql`
{
  is_valid
  booking_id
  booking {
    orders {
      accommodation_id
      accommodation {
        room {
          name
        }
        id
      }
      booking_id
      created_at
      description
      e_order_status {
        comment
        id
      }
      id
      invoice_id
      order_no
      order_type
      qty
      price
      service_charge
      service_id
      status
      total_price
      updated_at
      service {
        created_at
        id
        is_active
        name
        price
        updated_at
      }
    }
  }
  created_at
  e_invoice_status {
    comment
    id
  }
  id
  invoice_no
  status
  subtotal
  tax
  total
  updated_at
  invoice_payments(order_by: {created_at: desc}) {
    payment_id
    received_currency
    disbursement{
      id
      }
    amount
    created_at
    e_payment_status {
      comment
      id
    }
    e_payment_type {
      id
      comment
    }
    id
    invoice_id
    payment_no
    payment_type
    deposit_id
    payment_transaction_id
    credit_card_number
    deposit {
      amount
      booking_id
      created_at
      deposit_no
      id
      status
      tenant_id
      type
      updated_at
      e_deposit_status {
        comment
        id
      }
      e_deposit_type {
        comment
        id
      }
    }
    remarks
    status
    updated_at
  }
  orders(order_by: {created_at: desc}) {
    tenant_id
    accommodation_id
    booking_id
    created_at
    description
    id
    invoice_id
    order_no
    order_type
    price
    qty
    service_charge
    service_id
    status
    total_price
    updated_at
  }
  invoice_items(order_by: {created_at: desc}) {
    accommodation_id
    booking_id
    created_at
    description
    id
    invoice_id
    order_no
    order_type
    price
    qty
    service_charge
    service_id
    order_id
    total_price
    updated_at
  }
     invoice_refunds(order_by: {created_at: desc}) {
    id
    refund_id
    refund_no
    tenant_id
    booking_id
    order_id
    deposit_id
    refund_type
    amount
    status
    created_at
    updated_at
    beneficiary_name
    beneficiary_acc_no
    remarks
    invoice_id
  }
}
`;

const GET_ONE_INVOICES = gql`
{
  is_valid
  booking_id
  booking {
    orders {
      accommodation_id
      accommodation {
        room {
          name
        }
        id
      }
      booking_id
      created_at
      description
      e_order_status {
        comment
        id
      }
      id
      invoice_id
      order_no
      order_type
      qty
      price
      service_charge
      service_id
      status
      total_price
      updated_at
      service {
        created_at
        id
        is_active
        name
        price
        updated_at
      }
    }
  }
  created_at
  e_invoice_status {
    comment
    id
  }
  id
  invoice_no
  status
  subtotal
  tax
  total
  updated_at
  invoice_payments(order_by: {created_at: desc}) {
    payment_id
    received_currency
    disbursement{
      id
      }
    amount
    created_at
    e_payment_status {
      comment
      id
    }
    e_payment_type {
      id
      comment
    }
    id
    invoice_id
    payment_no
    payment_type
    deposit_id
    payment_transaction_id
    credit_card_number
    deposit {
      amount
      booking_id
      created_at
      deposit_no
      id
      status
      tenant_id
      type
      updated_at
      e_deposit_status {
        comment
        id
      }
      e_deposit_type {
        comment
        id
      }
    }
    remarks
    status
    updated_at
  }
  orders(order_by: {created_at: desc}) {
    tenant_id
    accommodation_id
    booking_id
    created_at
    description
    id
    invoice_id
    order_no
    order_type
    price
    qty
    service_charge
    service_id
    status
    total_price
    updated_at
  }
  invoice_items(order_by: {created_at: desc}) {
    accommodation_id
    booking_id
    created_at
    description
    id
    invoice_id
    order_no
    order_type
    price
    qty
    service_charge
    service_id
    order_id
    total_price
    updated_at
  }
    invoice_refunds(order_by: {created_at: desc}) {
    id
    refund_id
    refund_no
    tenant_id
    booking_id
    order_id
    deposit_id
    refund_type
    amount
    status
    created_at
    updated_at
    beneficiary_name
    beneficiary_acc_no
    remarks
    invoice_id
  }
}
`;

export { GET_LIST_INVOICES, GET_ONE_INVOICES }
