import * as React from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import {
  useTranslate,
  DashboardMenuItem,
  MenuProps,
  ReduxState,
} from "react-admin";
import { MenuItem } from "@material-ui/core";
import SubMenu from "./SubMenu";
import MenuItemLink from "../components/MenuItemLink.js";
import icHome from "../../images/ico_home.png";
import icFrontdesk from "../../images/ico_frontdesk.png";
import icBooking from "../../images/ico_booking.png";
import icAcco from "../../images/ico_acco.png";
import icDeposit from "../../images/ico_deposit.png";
import icRefund from "../../images/ico_refund.png";
import icOrder from "../../images/ico_order.png";
import icInvoice from "../../images/ico_invoice.png";
import icHk from "../../images/ico_hk.png";
import icMaintenance from "../../images/ico_maintenance.png";
import icGuest from "../../images/ico_guest.png";
import ico_guest from "../../images/ico_guest_1.png";
import icCompany from "../../images/ico_company.png";
import icRoomtype from "../../images/ico_roomtype.png";
import icRoomrate from "../../images/ico_roomrate.png";
import icRoom from "../../images/ico_room.png";
import icUser from "../../images/ico_user.png";
import icProperty from "../../images/ico_property.png";
import icConnect from "../../images/ico_connect.png";
import isCancel from "../../images/ico_maintenance.png";
import ico_time from "../../images/ico_time.png";
import ico_sales from "../../images/ico_sales.png";
import ico_service_meals from "../../images/ico_service_meals.png";
import ico_accounting from "../../images/ico_accounting.png";
import icon_bed from "../../images/icon_bed.png";
import ico_report from "../../images/ico_report.png";
import ico_check_in_check_out from "../../images/ico_check_in_check_out.png";
import ico_financial from "../../images/ico_financial.png";

const Menu = ({ dense = false }) => {
  const history = useHistory();
  const authInfo = JSON.parse(localStorage.getItem("auth"));
  const subscriptionSummary = authInfo != null ? authInfo.merchantCore?.subscriptionSummary : null
  const noTenantSelected = authInfo?.tenant?.id === undefined;

  const [state, setState] = useState({
    menuBookings: true,
    menuConfigurations: true,
    menuCustomers: true,
    menuReports: true,
    channelManager: true,

  });
  const translate = useTranslate();
  const open = useSelector((state) => state.admin.ui.sidebarOpen);
  useSelector((state) => state.theme); // force rerender on theme change
  const classes = useMenuStyles();

  const handleToggle = (menu) => {
    setState((state) => ({ ...state, [menu]: !state[menu] }));
  };

  const handleHome = () => {
    let auth = JSON.parse(localStorage.getItem("auth"));
    auth.tenant = undefined;
    auth["tenant.id"] = null;
    auth.tenant_id = null;
    localStorage.setItem("auth", JSON.stringify(auth));
    setTimeout(() => {
      history.push("/");
    }, 1000);
  };

  return (
    <div
      style={{
        background: "white",
        borderRight: "1px solid #e0e0e3",
        paddingRight: 10,
        height: "100%",
      }}
    >
      {authInfo?.role === "manager" && noTenantSelected ? (
        <>
          <MenuItem
            className={classes.root}
            activeClassName={classes.active}
            tabIndex={0}
            dense={dense}
            onClick={() => history.push("/tenants")}
          >
            <img
              src={icProperty}
              style={{ width: 27, height: 25, marginLeft: 10, marginRight: 18 }}
            />
            {translate(`ra.label.property`, { smart_count: 2 })}
          </MenuItem>
          <MenuItem
            className={classes.root}
            activeClassName={classes.active}
            tabIndex={0}
            dense={dense}
            onClick={() => history.push("/users?role=manager")}
          >
            <img
              src={icUser}
              style={{ width: 27, height: 25, marginLeft: 10, marginRight: 18 }}
            />
            Manager
          </MenuItem>
          {/* <MenuItem
            className={classes.root}
            activeClassName={classes.active}
            tabIndex={0}
            dense={dense}
            onClick={() => history.push("/connect-rideum")}
          >
            <img
              src={icConnect}
              style={{ width: 27, height: 25, marginLeft: 10, marginRight: 18 }}
            />
            {translate(`ra.label.connect_rideum`, { smart_count: 2 })}
          </MenuItem> */}
        </>
      ) : (
        <>
          {authInfo?.role === "manager" ? (
            <MenuItem
              className={classes.root}
              activeClassName={classes.active}
              tabIndex={0}
              dense={dense}
              onClick={handleHome}
            >
              <img
                src={icHome}
                style={{
                  width: 27,
                  height: 25,
                  marginLeft: 10,
                  marginRight: 18,
                }}
              />
              {translate(`ra.label.home`, { smart_count: 2 })}
            </MenuItem>
          ) : null}

          {authInfo?.role !== "housekeeper" ? (
            <MenuItemLink
              to={{
                pathname: "/frontDesk",
                state: { _scrollToTop: true },
              }}
              primaryText={translate(`ra.label.cockpit`, {
                smart_count: 2,
              })}
              leftIcon={icFrontdesk}
              dense={dense}
            />
          ) : null}
          {authInfo?.role !== 'housekeeper' ? (<MenuItemLink to={{ pathname: "/dashboard", state: { _scrollToTop: true } }} svgIcon={<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" style={{ width: '1.5rem', padding: '0.4rem', marginLeft: '0.4rem' }}>
            <path stroke-linecap="round" stroke-linejoin="round" d="M7.5 14.25v2.25m3-4.5v4.5m3-6.75v6.75m3-9v9M6 20.25h12A2.25 2.25 0 0 0 20.25 18V6A2.25 2.25 0 0 0 18 3.75H6A2.25 2.25 0 0 0 3.75 6v12A2.25 2.25 0 0 0 6 20.25Z" />
          </svg>
          } primaryText={translate("ra.label.insight", { smart_count: 2 })} dense={dense} />
          ) : null}

          {authInfo?.role !== "housekeeper" ? (
            <SubMenu
              handleToggle={() => handleToggle("menuBookings")}
              isOpen={state.menuBookings}
              name="ra.label.booking"
              icon={icBooking}
              dense={dense}
            >
              <MenuItemLink
                to={{
                  pathname: "/bookings",
                  state: { _scrollToTop: true },
                }}
                primaryText={translate(`ra.label.bookings`, {
                  smart_count: 2,
                })}
                leftIcon={icBooking}
                dense={dense}
              />
              <MenuItemLink
                to={{
                  pathname: "/orders",
                  state: { _scrollToTop: true },
                }}
                primaryText={translate(`ra.label.orders`, {
                  smart_count: 2,
                })}
                leftIcon={icOrder}
                dense={dense}
              />
              <MenuItemLink
                to={{
                  pathname: "/invoices",
                  state: { _scrollToTop: true },
                }}
                primaryText={translate(`ra.label.invoices`, {
                  smart_count: 2,
                })}
                leftIcon={icInvoice}
                dense={dense}
              />
            </SubMenu>
          ) : null}

          {subscriptionSummary && subscriptionSummary.housekeeping ?
            (
              <MenuItemLink
                to={{
                  pathname: "/housekeeping",
                  state: { _scrollToTop: true },
                }}
                primaryText={translate(`ra.label.housekeeping`, {
                  smart_count: 2,
                })}
                leftIcon={icHk}
                dense={dense}
              />)
            : null
          }

          {
            subscriptionSummary && subscriptionSummary.maintenance ?
              (
                authInfo?.role !== "housekeeper" ? (
                  <MenuItemLink
                    to={{
                      pathname: "/maintenances",
                      state: { _scrollToTop: true },
                    }}
                    primaryText={translate(`ra.label.maintenances`, {
                      smart_count: 2,
                    })}
                    leftIcon={icMaintenance}
                    dense={dense}
                  />
                ) : null)
              : null
          }

          {authInfo?.role !== "housekeeper" ? (
            <SubMenu
              handleToggle={() => handleToggle("menuCustomers")}
              isOpen={state.menuCustomers}
              name="ra.label.customer"
              icon={icGuest}
              dense={dense}
            >
              {/* <MenuItemLink
                to={{
                  pathname: "/guests",
                  state: { _scrollToTop: true },
                }}
                primaryText={translate(`ra.label.guests`, {
                  smart_count: 2,
                })}
                leftIcon={icGuest}
                dense={dense}
              /> */}
              <MenuItemLink
                to={{
                  pathname: "/companies",
                  state: { _scrollToTop: true },
                }}
                primaryText={translate(`ra.label.companies`, {
                  smart_count: 2,
                })}
                leftIcon={icCompany}
                dense={dense}
              />
              <MenuItemLink
                to={{
                  pathname: "/guests",
                  state: { _scrollToTop: true },
                }}
                primaryText={translate(`ra.label.guest`, {
                  smart_count: 2,
                })}
                leftIcon={ico_guest}
                dense={dense}
              />
            </SubMenu>
          ) : null}

          {authInfo?.role !== "housekeeper" ? (
            <SubMenu
              handleToggle={() => handleToggle("menuConfigurations")}
              isOpen={state.menuConfigurations}
              name="ra.label.configuration"
              icon={icRoomtype}
              dense={dense}
            >
              <MenuItemLink
                to={{
                  pathname: "/cancellation_policies",
                  state: { _scrollToTop: true },
                }}
                primaryText={`Cancellation Policies`}
                leftIcon={isCancel}
                dense={dense}
              />
              <MenuItemLink
                to={{
                  pathname: "/room_types",
                  state: { _scrollToTop: true },
                }}
                primaryText={translate("ra.label.room_types", {
                  smart_count: 2,
                })}
                leftIcon={icRoomtype}
                dense={dense}
              />
              <MenuItemLink
                to={{
                  pathname: "/room_rates",
                  state: { _scrollToTop: true },
                }}
                primaryText={translate("ra.label.room_rates", {
                  smart_count: 2,
                })}
                leftIcon={icRoomrate}
                dense={dense}
              />
              <MenuItemLink to={{
                pathname: "/dynamic_rates",
                state: { _scrollToTop: true }
              }}
                primaryText={translate("ra.label.dynamic_rates")}
                svgIcon={(<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1} stroke="currentColor" style={{ width: "1.5rem", height: "1.5rem", paddingLeft: "0.4rem", paddingRight: "0.4rem", marginLeft: "0.3rem", marginRight: "0.5rem" }}>
                  <path strokeLinecap="round" strokeLinejoin="round" d="M3 13.125C3 12.504 3.504 12 4.125 12h2.25c.621 0 1.125.504 1.125 1.125v6.75C7.5 20.496 6.996 21 6.375 21h-2.25A1.125 1.125 0 0 1 3 19.875v-6.75ZM9.75 8.625c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125v11.25c0 .621-.504 1.125-1.125 1.125h-2.25a1.125 1.125 0 0 1-1.125-1.125V8.625ZM16.5 4.125c0-.621.504-1.125 1.125-1.125h2.25C20.496 3 21 3.504 21 4.125v15.75c0 .621-.504 1.125-1.125 1.125h-2.25a1.125 1.125 0 0 1-1.125-1.125V4.125Z" />
                </svg>
                )}
                dense={dense}
              />
              <MenuItemLink
                to={{
                  pathname: "/rooms",
                  state: { _scrollToTop: true },
                }}
                primaryText={translate(`ra.label.rooms`, {
                  smart_count: 2,
                })}
                leftIcon={icRoom}
                dense={dense}
              />
              {subscriptionSummary && subscriptionSummary.service ?
                (
                  <MenuItemLink
                    to={{
                      pathname: "/services",
                      state: { _scrollToTop: true },
                    }}
                    primaryText={translate("ra.label.room_services", {
                      smart_count: 2,
                    })}
                    leftIcon={ico_service_meals}
                    dense={dense}
                  />)
                : null}
            </SubMenu>
          ) : null}

          {authInfo?.role === "manager" ? (
            <MenuItemLink
              to={{
                pathname: "/users",
                state: { _scrollToTop: true },
              }}
              primaryText={translate(`ra.label.users`, {
                smart_count: 2,
              })}
              leftIcon={icUser}
              dense={dense}
            />
          ) : null}

          {authInfo?.role !== "housekeeper" ? (
            <><SubMenu
              handleToggle={() => handleToggle("menuReports")}
              isOpen={state.menuReports}
              name="ra.label.reports"
              icon={ico_report}
              dense={dense}
            >
              <MenuItemLink to={{ pathname: '/account-balance', state: { _scrollToTop: true } }}
                primaryText={translate(`ra.label.account_balance_report`, {
                  smart_count: 2,
                })}
                leftIcon={ico_accounting}
                dense={dense} />

              <MenuItemLink to={{ pathname: '/financial-report', state: { _scrollToTop: true } }}
                primaryText={translate(`ra.label.financial_report`, {
                  smart_count: 2,
                })}
                leftIcon={ico_financial}
                dense={dense} />

              <MenuItemLink to={{ pathname: '/end-of-day-reports', state: { _scrollToTop: true } }}
                primaryText={translate(`ra.label.end_of_day`, {
                  smart_count: 2,
                })}
                leftIcon={ico_time}
                dense={dense}
              />

              <MenuItemLink
                to={{
                  pathname: "/sales-report",
                  state: { _scrollToTop: true },
                }}
                primaryText={translate(`ra.label.sales`, {
                  smart_count: 2,
                })}
                leftIcon={ico_sales}
                dense={dense}
              />

              <MenuItemLink to={{ pathname: '/checkin-checkout-reports', state: { _scrollToTop: true } }}
                primaryText={translate(`ra.label.check_in_check_out`, {
                  smart_count: 2,
                })}
                leftIcon={ico_check_in_check_out}
                dense={dense} />

              <MenuItemLink
                to={{
                  pathname: "/occupancy-report",
                  state: { _scrollToTop: true },
                }}
                primaryText={translate(`ra.label.occupancy`, {
                  smart_count: 2,
                })}
                leftIcon={icon_bed}
                dense={dense}
              />

              <MenuItemLink
                to={{
                  pathname: "/deposits",
                  state: { _scrollToTop: true },
                }}
                primaryText={translate(`ra.label.deposits`, {
                  smart_count: 2,
                })}
                leftIcon={icDeposit}
                dense={dense}
              />

              <MenuItemLink
                to={{
                  pathname: "/refunds",
                  state: { _scrollToTop: true },
                }}
                primaryText={translate(`ra.label.refunds`, {
                  smart_count: 2,
                })}
                leftIcon={icRefund}
                dense={dense}
              />
              {/* <MenuItemLink
                to={{
                  pathname: "/accounting-report",
                  state: { _scrollToTop: true },
                }}
                primaryText={translate(`ra.label.accounting`, {
                  smart_count: 2,
                })}
                leftIcon={ico_accounting}
                dense={dense}
              /> */}
              {/* <MenuItemLink
                to={{
                  pathname: "/booking-balance-report",
                  state: { _scrollToTop: true },
                }}
                primaryText={translate(`ra.label.bookingBalance`, {
                  smart_count: 2,
                })}
                leftIcon={icCompany}
                dense={dense}
              /> */}

            </SubMenu>
            </>
          ) : null}

          {
            subscriptionSummary && subscriptionSummary.channelManager ?
              (authInfo?.role !== "housekeeper" ? (
                <SubMenu
                  handleToggle={() => handleToggle("channelManager")}
                  isOpen={state.channelManager}
                  name="ra.label.channel_manager"
                  icon={icUser}
                  dense={dense}
                >
                  <MenuItemLink
                    to={{
                      pathname: "/cm_accounts",
                      state: { _scrollToTop: true },
                    }}
                    primaryText={translate(`ra.label.accounts`, {
                      smart_count: 2,
                    })}
                    leftIcon={icCompany}
                    dense={dense}
                  />
                  <MenuItemLink
                    to={{
                      pathname: "/cm_room_type_maps",
                      state: { _scrollToTop: true },
                    }}
                    primaryText={translate(`ra.label.room_types`, {
                      smart_count: 2,
                    })}
                    leftIcon={icCompany}
                    dense={dense}
                  />
                  <MenuItemLink
                    to={{
                      pathname: "/cm_room_type_rate_maps",
                      state: { _scrollToTop: true },
                    }}
                    primaryText={translate(`ra.label.room_rates`, {
                      smart_count: 2,
                    })}
                    leftIcon={icCompany}
                    dense={dense}
                  />
                  {/* <MenuItemLink
                    to={{
                      pathname: "/cm_2fa_sessions",
                      state: { _scrollToTop: true },
                    }}
                    primaryText={translate(`ra.label.fASessions`, {
                      smart_count: 2,
                    })}
                    leftIcon={icConnect}
                    dense={dense}
                  /> */}
                </SubMenu>
              ) : null)
              : null
          }
        </>
      )}
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  open: {
    width: 200,
  },
  closed: {
    width: 55,
  },
}));

const useMenuStyles = makeStyles(
  (theme) => ({
    root: {
      color: theme.palette.text.secondary,
    },
    active: {
      color: theme.palette.text.primary,
    },
    icon: { minWidth: theme.spacing(5) },
  }),
  { name: "RaMenuItemLink" }
);

export default Menu;
